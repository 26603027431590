//jQuery.noConflict();

jQuery(document).ready(function ($) {
    $('.slide-principal').owlCarousel({
        pagination: true,
        nav: false,
        loop: true,
        dots: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    $('.slide-marcas').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: false,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            768: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    });


    $('.slide-entradas').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            }
        }
    });




    

    $(".owl-prev").html('<i class="fas fa-chevron-left"></i>');
    $(".owl-next").html('<i class="fas fa-chevron-right"></i>');


    //Go through each carousel on the page
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });


    //Go through each carousel on the page
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).removeAttr('role');
        });
    });

    // apply meta info to next and previous buttons and make them focusable
    $('.owl-carousel').find('.owl-prev').attr('role','button').attr('title','Previous');
    $('.owl-carousel').find('.owl-next').attr('role','button').attr('title','Next');
    $('.owl-carousel, .owl-prev, .owl-next').attr('tabindex','0');

    

    // Bootstrap Auto-Hiding Navbar 
    $(".header").autoHidingNavbar();

    // Highlight the top nav as scrolling occurs
    $(window).scroll(function() {
        if ($(document).scrollTop() > 100) {
            $('.header').addClass('shrink');
        } else {
            $('.header').removeClass('shrink');
        }
    });


    // smooth scrolling # from external pages
    /*
    $(window).on("load", function () {
        var urlHash = window.location.href.split("#")[1];
        if (urlHash &&  $('#' + urlHash).length) {
            $('html,body').animate({
                scrollTop: $('#' + urlHash).offset().top - 0
            }, 800);
        }
    });
    */






    $('.colapsar').click(function() {
        $('.colapsar').removeClass('current');
        $(this).toggleClass('current');
        $('.modulo-text').removeClass('current');
        var $target = $(this).attr('href');
        $($target).toggleClass('current');
        return false;
    });


    $('.colapsar-ampliacion').click(function() {
        $('.colapsar-ampliacion').removeClass('current');
        $(this).addClass('current');
        $('.ampliacion-text').removeClass('current');
        var $target = $(this).attr('href');
        $($target).addClass('current');
        return false;
    });
    


    $('.procedimientos .modulo-text:first').addClass('current');
    $('.procedimientos .colapsar:first').addClass('current');


 


    // Video
    $(".video-post").on("click", function(){
      var elm = $(this),
          conts   = elm.contents(),
          le      = conts.length,
          ifr     = null;
      for(var i = 0; i<le; i++){
          if(conts[i].nodeType == 8) ifr = conts[i].textContent;
      }
      elm.addClass("player").html(ifr);
      elm.off("click");
    });




    // Magic popup
    // 'src/js/vendors/jquery.magnific-popup.js'
    $('.popup-link').magnificPopup({
        type: 'image'
        // other options
    });


    $('.popup-modal').magnificPopup({
        type: 'inline',
        midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });


    $('.popup-gallery, .slide-fotos').magnificPopup({
        delegate: '.owl-item:not(.cloned) a',
        type: 'image',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup 
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: true,
        fixedContentPos: false,
       
    });


    // Contact
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpforms-form .wpforms-field-medium').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('button_white');
    $('.wpforms-submit').addClass('button_white');

    
    //$('.input-xlarge').addClass('form-control');


    // Modal



});
